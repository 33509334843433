<template>
  <sub-page
      :title="$t('Documents')"
      v-model="$store.state.settings.pageData"
      :header-action="openNewDocument"
      :header-action-condition="items.length > 0"
      :header-action-text="$t('documentflow.document.add')"
      icon="mdi-file-document-multiple-outline"

      :no-content-condition="items.length === 0"
      :no-content-button-action="openNewDocument"
      no-content-button-text="documentflow.document.add"
      no-content-text="documentflow.document.no_content"
      fill-height

      :filters="filtersSelect"
  >
    <template #default>
      <ws-data-table
          class="mt-5"
          :items="itemsFiltered"
          :headers="headers"
          :search="$store.state.settings.filtersData.search"
          :row-action="($event) => openDocumentDialog($event)"
          :context-actions-select="documentActionsSelect"
          @contextAction="($event , item) => documentAction($event,item)"

      >

        <template #item.date="{item}">
          <h5 class="text-no-wrap">{{ MONTH_DAY_TIME(item.date , false) }}</h5>
        </template>

        <template #item.name="{item}">
          <h5 style="min-width: 300px" :style="`color : ${wsACCENT}`">{{ item.name }}</h5>
        </template>

        <template #item.structure_entity_name="{item}">
          <h5 class="shortenText" >{{ item.structure_entity_name }}</h5>
        </template>

        <template #item.code="{item}">
          <h5 class="text-no-wrap" >{{ item.code }}</h5>
        </template>

        <template #item.is_signed="{item}">
          <div class="d-flex justify-center">
            <v-icon v-if="!item.is_declined" :color="item.is_signed ? wsACCENT : null">mdi-check-decagram</v-icon>
            <v-icon v-else :color="wsATTENTION" >mdi-alert</v-icon>
          </div>
        </template>

        <template #item.action="{item , hover}">
          <div style="width : 40px">
            <ft-select
                @input="documentAction($event , item)"
                :items="documentActionsSelect"
            >
              <v-btn v-if="hover" icon :color="wsACCENT">
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </ft-select>
          </div>

        </template>

      </ws-data-table>
    </template>

    <template #dialog>

      <ws-dialog
          v-if="displayDialog"
          @save="addEditDocument"
          v-model="displayDialog"
          :title="newEntity ? $t('documentflow.document.add') : $t('documentflow.document.edit')"
          width="476"
          fill-height
          fullscreen-align="right"
          not-centered
      >
        <!-- Document Name -->
        <ws-text-field
            v-model="entityData.name"
            :label="$t('Name')"
            :placeholder="$t('EnterName')"
            clearable
            :error="nameError"
            @input="nameError = null"
        />
        <!-- Structure Entity-->
        <ws-select
            @input="handleStructureChange"
            class="mt-6"
            v-model="entityData.structure_entity_id"
            :label="$t('StructureEntity')"
            :placeholder="$t('Choose')"
            :items="hrStructureEntitiesSelect"
            :error="structureError"
        />
        <!-- Document Type -->
        <ws-select
            @input="handleTypeChange"
            v-model="entityData.type"
            :label="$t('Type')"
            :placeholder="$t('Choose')"
            :items="documentTypesSelectFiltered"
            :error="typeError"

        />

        <!-- Document Code and Date  -->
        <v-row no-gutters class="mb-7" >
          <v-col cols="6" class="pr-1">
            <ws-text-field
                v-model="entityData.code"
                :label="$t('Number')"
                :placeholder="$t('documentflow.document.enter_code')"
                clearable
                :error="codeError"
                @input="codeError = null"
            />
          </v-col>
          <v-col cols="6" class="pl-1">
            <ws-date-picker
                v-model="entityData.date"
                :label="$t('Date')"
                :placeholder="$t('ChooseDate')"
                time-stamp
                :error="dateError"
                @input="dateError = null"
            />
          </v-col>
        </v-row>

        <!-- Signators -->
        <div>
          <h5 :style="`color : ${wsACCENT}`" class="mb-3 font-weight-regular">{{ $t('Signators') }}</h5>
          <v-sheet   color="transparent"  :set="!user ? user=[] : null" :style="`border : 1px solid ${wsDARKLIGHT} !important`" class="wsRoundedLight">

            <v-sheet
                v-for="(signator,index) in entityData.signators"
                :key="signator.user_id"
                color="transparent"
                class="px-3"
                min-height="50"
                height="50"
                max-height="50"
                :class="[{'mt-2' : index === 0 }]"
            >
              <v-sheet
                  :set="user[index] = getUser(signator.user_id)"
                  class="d-flex justify-space-between align-center fill-height"
                  style="width : 100%"
              >
                <div v-if="user && user[index]" class="d-flex align-center" >
                  <v-avatar class="mr-3" size="24" :color="wsLIGHTCARD">
                    <v-icon v-if="!user[index].img" dark>mdi-account</v-icon>
                    <v-img v-else :src="user[index].img"></v-img>
                  </v-avatar>
                  <div>
                    <h5 :style="`color : ${wsACCENT}`">{{ user[index].text }} </h5>
                    <ft-select
                        :disabled="user[index].positions_select.length < 2"
                        v-model="entityData.signators[index].position_id"
                        :items="user[index].positions_select"
                        :key="index"
                    >
                      <template #default="{text}">
                        <v-sheet color="transparent" class="d-flex justify-space-between align-center shorten-text">
                          <h5 class="font-weight-regular">
                            {{ text || '' }}
                          </h5>
                          <v-icon v-if="user[index].positions_select.length > 1" :color="wsACCENT">mdi-menu-down</v-icon>
                        </v-sheet>
                      </template>

                    </ft-select>
                  </div>

                </div>
                <div class="d-flex align-center">

                  <v-btn class="ml-5" @click="removeSignator(signator.user_id)" icon small>
                    <v-icon small>mdi-close</v-icon>
                  </v-btn>
                </div>

              </v-sheet>

            </v-sheet>
            <v-divider v-if="entityData.signators.length > 0" class="mt-3" :style="`border-color : ${wsDARKLIGHT}`" />
            <ws-select
                :key="updateTrigger"
                v-if="managersSelectFiltered.length > 0"
                @input="addSignator"
                :value="newSignator"
                :items="managersSelectFiltered"
                :placeholder="$t('documentflow.signature.choose_signator')"
                autocomplete
                :error="signatorError"
                flat solo
            >
              <template #item="{item}">

                <v-sheet  width="100%" max-width="400px" color="transparent" class="d-flex align-center" :style="`border-bottom : 1px solid ${wsBACKGROUND} !important`" height="46"  >


                  <v-avatar class="mr-3" size="24" :color="wsLIGHTCARD">
                    <v-icon v-if="!item.img" dark>mdi-account</v-icon>
                    <v-img v-else :src="item.img"></v-img>
                  </v-avatar>
                  <div>
                    <h5 :style="`color : ${wsACCENT}`">{{ item.text }} </h5>
                    <div class="shorten-text">
                      <h5   class="d-flex align-center font-weight-regular shorten-text"  v-if="item.positions_select && item.positions_select.length > 0">
                        {{ item.positions_select[0].text }}
                      </h5>
                    </div>

                  </div>



                </v-sheet>

              </template>
            </ws-select>

          </v-sheet>
        </div>
        <!-- Aprovals  -->
        <div>
          <h5 :style="`color : ${wsACCENT}`" class="mt-5 mb-3 font-weight-regular">{{ $t('documentflow.document.has_approval_signator') }}</h5>
          <v-sheet   color="transparent"  :set="!apruser ? apruser=[] : null" :style="`border : 1px solid ${wsDARKLIGHT} !important`" class="wsRoundedLight">

            <v-sheet
                v-for="(signator,index) in entityData.aprovals"
                :key="signator.user_id"
                color="transparent"
                class="px-3"
                min-height="50"
                height="50"
                max-height="50"
                :class="[{'mt-2' : index === 0 }]"
            >
              <v-sheet
                  :set="apruser[index] = getUser(signator.user_id , true)"
                  class="d-flex justify-space-between align-center "
                  style="width : 100%"
              >
                <div v-if="apruser && apruser[index]" class="d-flex align-center" >
                  <v-avatar class="mr-3" size="24" :color="wsLIGHTCARD">
                    <v-icon v-if="!apruser[index].img" dark>mdi-account</v-icon>
                    <v-img v-else :src="apruser[index].img"></v-img>
                  </v-avatar>
                  <div>
                    <h5 :style="`color : ${wsACCENT}`">{{ apruser[index].text }} </h5>
                    <ft-select
                        :disabled="apruser[index].positions_select.length < 2"
                        v-model="entityData.aprovals[index].position_id"
                        :items="apruser[index].positions_select"
                        :key="index"
                    >
                      <template #default="{text}">
                        <v-sheet color="transparent" class="d-flex justify-space-between align-center shorten-text">
                          <h5   class="font-weight-regular shorten-text">
                            {{ text || '' }}
                          </h5>
                          <v-icon v-if="apruser[index].positions_select.length > 1" :color="wsACCENT">mdi-menu-down</v-icon>
                        </v-sheet>

                      </template>

                    </ft-select>
                  </div>

                </div>
                <div class="d-flex align-center">

                  <v-btn class="ml-5" @click="removeSignator(signator.user_id , true)" icon small>
                    <v-icon small>mdi-close</v-icon>
                  </v-btn>
                </div>

              </v-sheet>

            </v-sheet>
            <v-divider v-if="entityData.aprovals.length > 0" class="mt-3" :style="`border-color : ${wsDARKLIGHT}`" />
            <ws-select
                :key="updateTrigger"
                v-if="managersSelectFiltered.length > 0"
                @input="addSignator($event, true)"
                :value="newSignator"
                :items="managersSelectFiltered"
                :placeholder="$t('documentflow.signature.choose_signator')"
                autocomplete
                :error="signatorError"
                flat solo
            >
              <template #item="{item}">

                <v-sheet width="100%" max-width="400px" color="transparent" class="d-flex align-center" :style="`border-bottom : 1px solid ${wsBACKGROUND} !important`" height="46"  >
                  <v-avatar class="mr-3" size="24" :color="wsLIGHTCARD">
                    <v-icon v-if="!item.img" dark>mdi-account</v-icon>
                    <v-img v-else :src="item.img"></v-img>
                  </v-avatar>
                  <div>
                    <h5 :style="`color : ${wsACCENT}`">{{ item.text }} </h5>
                    <div class="shorten-text">
                      <h5   class="d-flex align-center font-weight-regular shorten-text"  v-if="item.positions_select && item.positions_select.length > 0">
                        {{ item.positions_select[0].text }}
                      </h5>
                    </div>
                  </div>

                </v-sheet>

              </template>
            </ws-select>

          </v-sheet>

        </div>
        <!-- Aprovals  -->
        <ws-switch
            v-model="entityData.is_child"
            :placeholder="$t('documentflow.document.has_parent')"
        />
        <ws-text-field
            v-if="entityData.is_child"
            @change="checkParentDocument"
            v-model="entityData.parent_id"
            :label="$t('documentflow.document.parent.title')"
            :placeholder="$t('documentflow.document.parent.placeholder')"
        />

        <ws-switch
            v-model="entityData.has_extra_protection"
            :placeholder="$t('documentflow.document.has_extra_protection')"
        />

        <div class="mt-5 pb-3">
          <h5 class="mb-3 mt-5" :style="`color : ${wsACCENT}`">{{ $t('documentflow.document.sign_document') }}: </h5>
          <h4 class="mb-3 font-weight-regular" v-if="fileError" :style="`color : ${wsWARNING}`">{{ $t('ChooseFile') }}</h4>
          <ws-file-uploader
              v-if="!entityData.file"
              class="mt-5"
              @success="addFile"
              pdf
          />
          <v-sheet v-else class="d-flex justify-space-between align-center pa-3" :style="`border : 1px solid ${wsBACKGROUND}`" >
            <div class="d-flex align-center">
              <v-icon class="mr-2" :color="wsACCENT">mdi-file</v-icon>
              <h5 :style="`color : ${wsACCENT}`" class="shorten-text"> {{ entityData.file_name }}</h5>
            </div>
            <v-btn @click="deleteFile" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-sheet>

          <div v-if="entityData.file">
            <h5 class="mb-3 mt-5" :style="`color : ${wsACCENT}`">{{ $t('documentflow.document.original_document') }}: </h5>
            <ws-file-uploader
                v-if="!entityData.original_file"
                class="mt-5"
                @success="addFile($event , true)"
            />
            <v-sheet v-else class="d-flex justify-space-between align-center pa-3" :style="`border : 1px solid ${wsBACKGROUND}`" >
              <div class="d-flex align-center">
                <v-icon class="mr-2" :color="wsACCENT">mdi-file</v-icon>
                <h5 :style="`color : ${wsACCENT}`" class="shorten-text"> {{ entityData.original_file_name }}</h5>
              </div>
              <v-btn @click="deleteFile(true)" icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-sheet>

          </div>

        </div>



      </ws-dialog>

      <document-dialog
          v-if="displayDocumentDialog"
          v-model="displayDocumentDialog"
          :document="selectedDocument"
      />


    </template>


  </sub-page>
</template>

<script>
import {mapActions} from "vuex";
import documentDialog from "@/components/pages/businessDashboard/businessDocumentflow/UI/documentDialog";
export default {
  name: "Tasks",
  components : {
    documentDialog
  },
  data() {
    return {
      items : [],
      displayDialog : false,
      entityData : {
        signators : [],
        aprovals : []
      },
      documentTypesSelect : [],
      documentTypesData : {},
      documentDepartmentsSelect : [],
      hrStructureEntitiesSelect : [],
      managersSelect : [],
      newEntity : false,
      newSignator : null,
      updateTrigger : 0,
      file : {},
      originalFile : {},

      nameError : null,
      typeError : null,
      codeError : null,
      dateError : null,
      signatorError : null,
      fileError : null,
      displayDocumentDialog : false,
      selectedDocument : {}
    }
  },
  computed : {
    headers() {
      return [
        {  value : 'is_signed' , width : 10 , sortable : false },
        { text : this.$t('Date') , value : 'date' , width : 10 },
        { text : this.$t('Name') , value : 'name' },
        { text : this.$t('StructureEntity') , value : 'structure_entity_name' , width : 200 },
        { text : this.$t('Type') , value : 'type_name' , width : 60 },
        { text : this.$t('Code') , value : 'code' , width : 10 },
        { text : this.$t('Owner') , value : 'owner_name' , width : 10 },
        { value : 'public_key' , width : 10 , align : 'd-none' },
        { value : 'action' , width : 50 },
      ]
    },
    documentActionsSelect() {
      return [
        { text : this.$t('Open') ,  value : 'view' },
        { text : this.$t('Download') , value : 'download' },
      ]
      // { text : this.$t('Delete') , value : 'delete' },
    },
    documentTypesSelectFiltered() {
      let items = this.COPY(this.documentTypesSelect)

      if ( this.entityData.structure_entity_id ) {
        items = items.filter(el=> el.categories.includes(this.entityData.structure_entity_id))
      }

      return items
    },
    itemsFiltered() {
      let items = this.COPY(this.items)

      if ( this.$store.state.settings.filtersData.structure_entity_id ) {
        items = items.filter(el => el.structure_entity_id === this.$store.state.settings.filtersData.structure_entity_id )
      }

      if ( this.$store.state.settings.filtersData.type ) {
        items = items.filter(el => el.type === this.$store.state.settings.filtersData.type )
      }

      if ( this.$store.state.settings.filtersData.owner_id ) {
        items = items.filter(el => el.owner_id === this.$store.state.settings.filtersData.owner_id )
      }

      if ( [true,false].includes( this.$store.state.settings.filtersData.is_signed ) ) {
        if ( this.$store.state.settings.filtersData.is_signed === true ) {
          items = items.filter(el => el.is_signed === true )
        } else {
          items = items.filter(el => !el.is_signed  )
        }
      }

      return items
    },
    managersSelectFiltered() {
      let items = this.COPY(this.managersSelect)
      if ( !this.entityData.signators ) {
        return items
      }
      return items.filter(el => !(this.entityData.signators.map(el => el.user_id).includes(el.value) || this.entityData.aprovals.map(el => el.user_id).includes(el.value) )|| this.newSignator === el.value)
    },
    filtersSelect(){
      return [
        { text : this.$t('StructureEntity')  , value : 'structure_entity_id' , type : 'select' , items : this.hrStructureEntitiesSelect },
        { text : this.$t('Type')  , value : 'type' , type : 'select' , items : this.documentTypesSelect },
        { text : this.$t('IsSigned')  , value : 'is_signed' , type : 'select' , items : [
            { text : this.$t('IsSigned') , value : true },
            { text : this.$t('NotSigned'), value : false }
        ]},
        { text : this.$t('Owner')  , value : 'owner_id' , type : 'select' , items : this.managersSelect },
      ]
    },
  },
  methods : {
    ...mapActions('documentflow', [
      'GET_DOCUMENTS',
      'ADD_EDIT_DOCUMENT',
    ]),
    ...mapActions('upload', [ 'GET_PRIVATE_FILE' ]),

    checkParentDocument(code) {
      console.log('Check parent ajax' + code)
    },

    documentAction(action , item) {
      switch(action) {
        case 'view' : return this.openDocumentDialog(item)
        case 'download' : return this.downloadFile(item.file_data)
        case 'delete' : return this.deleteDocument(item)
      }
    },
    openDocumentDialog(event) {
      this.selectedDocument = event
      this.displayDocumentDialog = true
    },
    deleteDocument() {
      this.notify('delete')
    },


    handleStructureChange() {
      this.structureError = null
      this.entityData.type = null
      this.entityData.signators = []
      this.entityData.aprovals = []
    },
    handleTypeChange() {
      this.typeError = null
      let type = this.documentTypesSelect.find(el => el.value === this.entityData.type)
      if ( !this.entityData.type || !type ) {
        this.entityData.signators = []
        this.entityData.aprovals = []
        return
      }
      if ( this.documentTypesData && this.documentTypesData[this.entityData.type] ) {
        let typeDataAll = this.documentTypesData[this.entityData.type]
        if ( typeDataAll && typeDataAll.length > 0 ) {
          let typeData = typeDataAll.find(el => el.structure_entity_id === this.entityData.structure_entity_id )
          if ( typeData ) {
            this.entityData.signators = typeData.signators
            this.entityData.aprovals = typeData.aprovals
          }
        }
      }

    },

    async downloadFile(item) {

      let result = await this.GET_PRIVATE_FILE(item.uuid)
      if (!result) { return this.notify('File Not found') }
      var a = document.createElement('a');
      a.href = result.url;
      a.download = item.name;
      document.body.appendChild(a);
      a.click();
      a.remove();
    },

    getUser(value) {
      let user = this.managersSelect.find(el => el.value === value)
      if ( user ) {
        return user
      }
      return {}
    },
    getUserName(value) {
      let user = this.managersSelect.find(el => el.value === value)
      if ( user ) {
        return user.text
      }
      return ''
    },
    removeSignator(value , isAproval) {
      this.updateTrigger++
      if ( !isAproval ) {
        this.entityData.signators = this.entityData.signators.filter(el => el.user_id !== value)
      } else {
        this.entityData.aprovals = this.entityData.aprovals.filter(el => el.user_id !== value)
      }

    },
    addSignator(value , isAproval = false) {
      this.signatorError = null

      let user = this.getUser(value)

      let data = {
        user_id : value
      }
      if ( user.positions_select && user.positions_select.length > 0 ) {
        data.position_id = user.positions_select[0].value
      }

      if ( isAproval) {
        this.entityData.aprovals.push(data)
      } else {
        this.entityData.signators.push(data)
      }
      this.newSignator = null
      this.updateTrigger++
    },
    deleteFile(original) {
      if ( !original )  {
        this.file = {}
        this.entityData.file = null
        this.entityData.file_name = null
      } else {
        this.originalFile = {}
        this.entityData.original_file = null
        this.entityData.original_file_name = null
      }

    },
    addFile(file ,original = false) {
      this.fileError = null

      if ( !original )  {
        this.file = file
        this.entityData.file = file.uuid
        this.entityData.file_name = file.name
      } else {
        this.originalFile = file
        this.entityData.original_file = file.uuid
        this.entityData.original_file_name = file.name
      }

      this.entityData = this.COPY(this.entityData)
    },
    async addEditDocument() {

      if ( !this.entityData.name ) {
        this.nameError = true
        this.notify(this.$t('documentflow.document.name_error') , 'warning')
        return
      }
      if ( !this.entityData.type ) {
        this.typeError = true
        this.notify(this.$t('documentflow.document.type_error') , 'warning')
        return
      }
      if ( !this.entityData.code ) {
        this.codeError = true
        this.notify(this.$t('documentflow.document.code_error') , 'warning')
        return
      }

      if ( !this.entityData.date ) {
        this.dateError = true
        this.notify(this.$t('documentflow.document.date_error') , 'warning')
        return
      }

      if ( this.entityData.signators.length === 0 ) {
        this.signatorError = true
        this.notify(this.$t('documentflow.document.signator_error') , 'warning')
        return
      }

      if ( !this.entityData.file) {
        this.fileError = true
        this.notify(this.$t('documentflow.document.file_error') , 'warning')
        return
      }

      let result = await this.ADD_EDIT_DOCUMENT(this.entityData)
      if ( !result )  {
        this.ERROR()
        return
      }
      if ( this.newEntity) {
        this.items.unshift(result)
      } else {
        let index = this.items.findIndex(el => el.uuid === result.uuid)
        this.items[index] = this.COPY(result)
        this.items = this.COPY(this.items)
      }
      this.displayDialog = false
    },
    openNewDocument() {
      this.nameError = null
      this.typeError = null
      this.codeError = null
      this.dateError = null
      this.signatorError = null
      this.fileError = null

      this.newSignator = null
      this.newEntity = true
      this.entityData = this.COPY( {
        signators : [],
        aprovals : []
      })
      this.displayDialog = true
    },
    openEditDocument(item) {
      this.nameError = null
      this.typeError = null
      this.codeError = null
      this.dateError = null
      this.signatorError = null
      this.fileError = null

      this.newEntity = false
      this.entityData = this.COPY(item)
      this.displayDialog = true
    },

    // technical

    async initPage() {
      let result = await this.GET_DOCUMENTS()
      if ( !result )  {
        this.ERROR()
      }
      result.items.reverse()
      this.items = result.items
      this.documentTypesSelect = result.document_types_select
      this.documentTypesData = result.document_types_data
      this.documentDepartmentsSelect = result.document_departments
      this.managersSelect = result.managers_select
      this.hrStructureEntitiesSelect = result.structure_entities_select
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>